<template>
    <div>
        <div v-if="!loading">
            <timer :duration="timeLeft" @endTime="submitForm" @countdownProgress="handleCountdownProgress"
                   :isShow="sectionName.includes('EPPS')"/>

            <div v-for="(item, index) in questions" :key="index"
                 :class="[{ 'selected': questionIndex === index , 'displayed': isDisplayAll}, 'question-container']">
                <div v-if="item.question_type === 'text'">
                    <div class="row">
                        <div class="form-group col-12">
                            <label>{{index+1}}.) {{item.content}}</label>
                            <input v-model="answer[index].content" type="text" class="form-control"
                                   placeholder="Jawaban"
                                   @change="saveTempAnswer"
                                   @focus="saveTempAnswer">
                        </div>
                    </div>
                </div>

                <div v-if="item.question_type === 'number'">
                    <div class="row">
                        <div class="form-group col-12">
                            <label>{{index+1}}.) {{item.content}}</label>
                            <input v-model.number="answer[index].content" type="number" class="form-control"
                                   placeholder="Jawaban"
                                   @change="saveTempAnswer"
                                   @focus="saveTempAnswer">
                        </div>
                    </div>
                </div>

                <div v-if="item.question_type === 'date'">
                    <div class="row">
                        <div class="form-group col-12">
                            <label>{{index+1}}.) {{item.content}}</label>
                            <flat-pickr v-model="answer[index].content" :config=dateConfig type="date" class="form-control" placeholder="pilih tanggal"></flat-pickr>
                        </div>
                    </div>
                </div>

                <div v-else-if="item.question_type === 'radiobox'">
                    <div class="row">
                        <div class="form-group col-12">
                            <label>{{index+1}}.) {{item.content}}</label>
                            <div v-if="item.image_path">
                                <img :src="item.image_path" alt="" class="image">
                            </div>
                            <div class="form-check" v-for="(answerList, i) in item.answers" :key="i">
                                <input v-model="answer[index].answer_ids"
                                       class="form-check-input"
                                       @change="saveTempAnswer"
                                       @focus="saveTempAnswer"
                                       :id="`radio-${index}-${i}`"
                                       :name="`radio-${index}-${i}`"
                                       type="radio"
                                       :value="answerList.id">

                                <label class="form-check-label" :for="`radio-${index}-${i}`">
                                    {{answerList.content}}
                                </label>
                                <div v-if="answerList.image_path">
                                    <img :src="answerList.image_path" alt="" class="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else-if="item.question_type === 'checkbox'">
                    <div class="row">
                        <div class="form-group col-12">
                            <label>{{index+1}}.) {{item.content}}</label>
                            <div v-if="item.image_path">
                                <img :src="item.image_path" alt="" class="image">
                            </div>
                            <div class="form-check" v-for="(answerList, i) in item.answers" :key="i">
                                <input v-model="answer[index].answer_ids"
                                       class="form-check-input" type="checkbox"
                                       :value="answerList.id"
                                       @change="saveTempAnswer"
                                       @focus="saveTempAnswer"
                                       :id="`checkbox-${index}-${i}`">
                                <label class="form-check-label" :for="`checkbox-${index}-${i}`">
                                    {{answerList.content}}
                                </label>
                                <div v-if="answerList.image_path">
                                    <img :src="answerList.image_path" alt="" class="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else-if="item.question_type === 'rmib'">
                    <div class="row">
                        <div class="form-group col-12">
                            <label>{{index+1}}.) {{item.content}}</label>
                            <div class="form-inline" v-for="(answerList, i) in item.answers" :key="i">
                                <div class="col-md-3">
                                  <label class="form-label">
                                      {{answerList.content}}
                                  </label>
                                </div>
                                <div class="col-md-2">
                                  <div class="form-group">
                                    <input v-model="answer[index].content[i]"
                                           :min="1"
                                           :max="12"
                                           class="form-control" type="number"
                                           @change="handleRmib(index, i, answerList)"
                                           @focus="saveTempAnswer">
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div v-if="item.question_type === 'multiple_a_b_question'">
                    <div class="row">
                        <div class="form-group col-12">
                            <table class="table table-responsive">
                                <tr>
                                    <th class="text-center" width="5%">No</th>
                                    <th class="text-center" width="5%">P</th>
                                    <th class="text-center" width="5%">K</th>
                                    <th class="text-center">Gambaran Diri</th>
                                </tr>
                                <tr v-for="(answerList, i) in item.answers" :key="i">
                                    <td v-if="i===0" :rowspan="item.answers.length" class="align-middle text-center">
                                        {{index+1}}
                                    </td>
                                    <td class="text-center">
                                        <input type="radio"
                                               v-model="answer[index].answer_ids[0]"
                                               :disabled="answerList.id === answer[index].answer_ids[1]"
                                               :id="`a-question-${index}-${i}`"
                                               :value="answerList.id"
                                               @change="handleToggleAB(index,'P')"
                                               @focus="saveTempAnswer">
                                    </td>
                                    <td class="text-center">
                                        <input type="radio"
                                               v-model="answer[index].answer_ids[1]"
                                               :disabled="answerList.id === answer[index].answer_ids[0]"
                                               :id="`b-question-${index}-${i}`"
                                               :value="answerList.id"
                                               @change="handleToggleAB(index,'K')"
                                               @focus="saveTempAnswer">
                                    </td>
                                    <td>{{answerList.content}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <div v-else-if="item.question_type === 'cfit'">
                    <div class="row">
                        <div class="form-group col-12">
                            <label>{{index+1}}.) {{item.content}}</label>
                            <div v-if="item.image_path">
                                <img :src="item.image_path" alt="" class="image">
                            </div>
                            <div class="form-check" v-for="(answerList, i) in item.answers" :key="i">
                                <input v-model="answer[index].answer_ids"
                                       class="form-check-input" type="checkbox"
                                       :value="answerList.id"
                                       @change="saveTempAnswer"
                                       @focus="saveTempAnswer"
                                       :id="`checkbox-${index}-${i}`">
                                <label class="form-check-label" :for="`checkbox-${index}-${i}`">
                                    {{answerList.content}}
                                </label>
                                <div v-if="answerList.image_path">
                                    <img :src="answerList.image_path" alt="" class="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-12 text-right">
                    <button v-if="questions.length-1 > questionIndex && !isDisplayAll" @click="next"
                            class="btn btn-info">Next
                    </button>
                    <button v-else @click="submitForm" class="btn btn-info">Selanjutnya</button>
                </div>
            </div>
        </div>
        <div v-else><h1>loading...</h1></div>
    </div>
</template>

<script>
    import Rmib from '@/helper/Rmib';

    export default {
        name: "Quiz",
        components: {
            timer: () => import('@/components/QuizTimer')
        },
        props: ['questions', 'duration', 'isDisplayAll', 'sectionName'],
        data() {
            return {
                answer: [],
                questionIndex: 0,
                loading: true,
                timeLeft: this.duration * 60000,
                dateConfig: {
                  dateFormat: 'd/m/Y',
                  disableMobile: true
                }
            }
        },
        created() {
            this.init()
        },
        watch: {
            '$route.params.sectionId': function () {
                this.init()
            }
        },
        methods: {
            init() {
                this.loading = true;
                if (localStorage.getItem('answers-temp') !== null) {
                    const {answers, durationLeft, questionIndex} = JSON.parse(localStorage.getItem('answers-temp'));
                    this.answer = answers;
                    this.timeLeft = durationLeft;
                    this.questionIndex = questionIndex;
                } else {
                    this.answer = this.questions.map(question => ({
                        question_id: question.id,
                        answer_ids: [],
                        content: ((question.question_type === 'multiple_a_b_question') || (question.question_type === 'rmib')) ? [] : '',
                    }));
                    this.questionIndex = 0; //reset counter
                    this.timeLeft = this.duration * 60000; //reset timer
                }
                this.loading = false;
            },
            handleRmib(answerIndex, contentIndex, answerObject) {
              this.answer[answerIndex].answer_ids[contentIndex] = answerObject.id
              Rmib.validateAnswer(this.answer[answerIndex].content.map((x) => x))
              this.saveTempAnswer();
            },
            handleToggleAB(idx, type) {
                const template = ['P', 'K'];
                const index = template.findIndex(find => find === type);
                this.answer[idx].content[index] = type;
                this.saveTempAnswer();
            },
            handleCountdownProgress(time) {
                this.timeLeft = time.totalMilliseconds;
                this.saveTempAnswer();
            },
            next() {
                if (this.questions[this.questionIndex].question_type === 'rmib' &&
                  !Rmib.isValidAnswer(this.answer[this.questionIndex].content.map((x) => x))) {
                  return;
                }
                if (this.questionIndex < this.questions.length) {
                    this.questionIndex++;
                    this.saveTempAnswer();
                }
            },
            submitForm() {
                if (this.questions[this.questionIndex].question_type === 'rmib' &&
                  !Rmib.isValidAnswer(this.answer[this.questionIndex].content.map((x) => x))) {
                  return;
                }
                Rmib.storeGender(this.sectionName, this.questions, this.answer);
                this.$emit('submitForm', this.answer);
            },
            saveTempAnswer() {
                localStorage.setItem('answers-temp', JSON.stringify({
                    answers: this.answer,
                    durationLeft: this.timeLeft,
                    questionIndex: this.questionIndex,
                }))
            }
        }
    }
</script>

<style scoped>
    @media screen and (min-device-width: 1200px) {
      .image {
        height: 150px;
      }
    }
    .question-container.selected, .question-container.displayed {
        display: block !important;
    }

    .question-container, .question-container.displayed {
        display: none;
    }

    .table td, th {
        vertical-align: middle;
    }
</style>
