export default class Rmib {

  static isValidAnswer(answers) {
    let alertText = Rmib.validateAnswer(answers);
    if (alertText.length > 0) {
      return false;
    }
    return true;
  }

  static validateAnswer(answers) {
    let alertText = [];

    answers.sort()
    for (let i=0; i<answers.length - 1; i++) {
      if (answers[i] !== undefined && answers[i] == answers[i+1]) {
        let errorMessage = 'angka ' + answers[i] + ' ditemukan muncul lebih dari sekali!';
        alertText.push(errorMessage)
      }
    }
    if (Math.max(...answers) > 12) {
      alertText.push('angka maksimum yang dimasukkan adalah 12')
    }
    if (Math.min(...answers) < 1) {
      alertText.push('angka minimum yang dimasukkan adalah 1')
    }
    if (alertText.length > 0) {
      alert(alertText.join('\n'));
    }
    return alertText;
  }

  static storeGender(sectionName, questions, userAnswers) {
    if (sectionName.toLowerCase().indexOf('profil') == -1) {
      return;
    }
    // find question index
    let questionIndex = null;
    questions.forEach(function(question, index) {
      if (question.content.toLowerCase().indexOf('gender') != -1 ||
        question.content.toLowerCase().indexOf('jenis kelamin') != -1) {
        questionIndex = index;
      }
    });

    // find user answer
    let userGenderAnswerId = userAnswers[questionIndex].answer_ids;

    // set gender
    let gender = null;
    questions[questionIndex].answers.forEach(function(answer) {
      if (answer.id == userGenderAnswerId) gender = answer.content.toLowerCase();
    });

    if (['male', 'M', 'L', 'laki-laki', 'laki - laki', 'pria', 'cowok'].includes(gender)) gender = 'male';
    if (['female', 'F', 'P', 'perempuan', 'wanita', 'cewek'].includes(gender)) gender = 'female';

    localStorage.setItem('gender', gender);
  }
}
